/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { isArray, isEqual, omit } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { Modal as ModalTask } from "@screens/day/components/ListTasks";
import { Modal } from "@screens/day/components/ListTasks/Modal/Modal";
import { Item } from "@screens/day/components/ListTasks/taskItem/Item";
import { NotFound } from "@screens/task/components/notFound";
import { removeSelectedItemId } from "@store/attachedMeetingTasks/slice";
import { selectBusinessTasks, dropFilter, getFilteredList, paggingFilteredList, selectAllBusinessTasks } from "@store/businessTask";
import { TaskSubtypes, defaultFilter } from "@store/businessTask/constants";
import { Colors } from "@theme/colors";
import { EmptyBusinessTasks } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { TextFont } from "@ui/TextFont";
import EventHelperUtils from "@utils/event-helper.utills";
import { showToast } from "@utils/toast";

const eventHelperUtils = new EventHelperUtils();

interface IModalTag {
  isVisible: boolean;
  checkedTaskIds: string[];
  setIsVisible: (arg: boolean) => void;
  onTaskDelete: (id: string) => void;
  handleAttachTasks: (tasks: ITask[]) => void;
  close3DotMenu?: () => void;
}

export const ModalItemAttach = ({ isVisible, checkedTaskIds, onTaskDelete, setIsVisible, handleAttachTasks, close3DotMenu }: IModalTag) => {
  const dispatch = useDispatch();
  const [isDetailedTaskViewModalVisible, setIsDetailedTaskViewModalVisible] = useState(false);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const newlySelectedTasksIds = useRef<string[]>([]);
  const newlySelectedTasks = useRef<ITask[]>([]);
  const currenDetailedTaskId = useRef("");
  const phSearch = useIntl().formatMessage({ id: "search" });

  const {
    filter: { name, groupByType },
    filter,
    isLoadingList,
    isLoadingPaggingAll,
    errorMsg,
    allTasks: { content: allListTasks, total },
  } = useSelector(selectBusinessTasks);
  const groupedTasks = useSelector(selectAllBusinessTasks);

  const listTasks = useMemo(() => allListTasks.filter((task) => task.subType === TaskSubtypes.Business), [allListTasks]);

  const handleResetFilter = async () => {
    setSearch("");
    dispatch(getFilteredList({ name, groupByType }));
    dispatch(dropFilter({ name, groupByType }));
    applyEvent();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearch(value);
    eventHelperUtils.debounce(
      () =>
        dispatch(
          getFilteredList({
            status: "OPEN",
            name: value,
            pageable: {
              next: 20,
              current: 0,
              total: 0,
            },
          }),
        ),
      1000,
    );
  };

  const loadMoreData = async () => {
    if (listTasks.length >= total || isLoadingPaggingAll || errorMsg) return;

    try {
      dispatch(
        paggingFilteredList({
          status: "OPEN",
          pageable: {
            next: 20,
            current: allListTasks.length,
            total: 0,
          },
        }),
      );
    } catch (error) {
      return showToast("dataDidNotLoaded", "error");
    }
  };

  const current = useMemo(() => listTasks.length, [listTasks.length]);
  const isDefaultFilter = useMemo(() => isEqual(omit(filter, ["pageable"]), omit(defaultFilter, ["pageable"])), [filter]);

  const applyEvent = async (callback?: () => void) => {
    dispatch(
      getFilteredList({
        status: "OPEN",
        pageable: {
          next: 20,
          current: 0,
          total: 0,
        },
      }),
    );
    if (callback) callback();
  };

  const renderFooter = (
    <div>
      {current < total && !isLoadingPaggingAll && (
        <button onClick={loadMoreData} style={style.createTaskButtonText}>
          Показать еще
        </button>
      )}
      <RenderWithCondition condition={true}>
        {isLoadingPaggingAll ? (
          <>
            <div style={style.loaderContainer}>
              <ReactLoading type="spokes" color={Colors.LIGHT.green} height={25} width={25} />
            </div>
          </>
        ) : (
          <div />
        )}
      </RenderWithCondition>
    </div>
  );

  const handleSelectedTasks = useCallback(
    (task: ITask) => {
      const newlySelectedTasksIds = newlySelectedTasks.current?.map((item) => item.id) ?? [];

      if (newlySelectedTasksIds.includes(task.id)) {
        const resultTasks = [];

        for (const item of newlySelectedTasks.current) {
          if (item.id !== task.id) {
            resultTasks.push(item);
          }
        }

        newlySelectedTasks.current = resultTasks;

        return;
      }

      if (checkedTaskIds.includes(task.id)) {
        onTaskDelete(task.id);
        return;
      }

      if (!newlySelectedTasksIds.includes(task.id) && !checkedTaskIds.includes(task.id)) {
        newlySelectedTasks.current = [...newlySelectedTasks.current, task];
      }
    },
    [newlySelectedTasksIds, checkedTaskIds],
  );

  const onAddHandler = () => {
    handleAttachTasks(newlySelectedTasks.current);
    setIsVisible(false);
    close3DotMenu?.();
  };

  const checkIsChecked = (id: string) => (checkedTaskIds ? checkedTaskIds.includes(id) : false);

  const clearNewlySelectedItems = () => {
    const newlySelectedTasksIds = newlySelectedTasks.current?.map((item) => item.id) ?? [];

    for (const id of newlySelectedTasksIds) {
      dispatch(removeSelectedItemId(id));
    }
  };

  const handleItemPress = (id: string) => {
    setIsDetailedTaskViewModalVisible(true);
    currenDetailedTaskId.current = id;
  };

  const renderItem = (index: number, item: ITask, isLastItem: boolean) => (
    <>
      <Item
        key={item.id}
        index={index}
        item={item}
        press={() => handleItemPress(item.id)}
        checkbox
        meetingTaskStyles
        handleCheckedTask={handleSelectedTasks}
        isChecked={checkIsChecked(item.id)}
        onRightClick={() => {}}
      />
      {isLastItem && renderFooter}
    </>
  );

  const renderTasks = useMemo(() => {
    const isEmptyArray = !listTasks.length;

    if (isEmptyArray && isDefaultFilter) {
      return (
        <EmptyBusinessTasks
          press={() => {
            setIsAddTaskModalVisible(true);
          }}
        />
      );
    } else if (isEmptyArray) {
      return (
        <NotFound
          handleResetFilter={handleResetFilter}
          disableButton
          titleTextId="taskNotFoundInfo"
          descriptionTextId="taskNotFoundDescriptionInfo"
        />
      );
    } else if (isArray(groupedTasks)) {
      const renderList = groupedTasks.map((item, index) => {
        const isLastItem = groupedTasks.length - 1 === index;
        return renderItem(index, item, isLastItem);
      });

      return renderList;
    }
  }, [groupedTasks, listTasks, isLoadingPaggingAll, isDefaultFilter]);

  const handleClose = () => {
    clearNewlySelectedItems();
    setIsVisible(false);
    close3DotMenu?.();
  };

  useEffect(() => {
    applyEvent();
  }, []);

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose}>
      <HeaderModal title="addTask" onClose={handleClose} onCancel={handleClose} onSave={onAddHandler} titleSave="add" isEdit={true} />
      <div style={style.main}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={style.searchInput}>
            <SvgSearch />
            <Input value={search} onChange={handleSearch} placeholder={phSearch} maxLength={255} />
          </div>
          <RenderWithCondition condition={Boolean(search)}>
            <button style={style.searchButton} onClick={handleResetFilter}>
              <TextFont style={style.searchButtonText}>
                <FormattedMessage id="drop" />
              </TextFont>
            </button>
          </RenderWithCondition>
        </div>

        <button
          onClick={() => {
            setIsAddTaskModalVisible(true);
          }}
        >
          <TextFont style={style.createTaskButtonText}>
            <FormattedMessage id="createTask" />
          </TextFont>
        </button>

        <div style={style.mobContainer}>
          <LoaderRenderWithCondition condition={isLoadingList}>
            <div style={{ height: groupByType === "NONE" ? "93%" : "100%", paddingBottom: 100 }}>{renderTasks}</div>
          </LoaderRenderWithCondition>
        </div>
      </div>

      <ModalRenderWithCondition condition={isAddTaskModalVisible}>
        <Modal isVisible={isAddTaskModalVisible} setIsVisible={setIsAddTaskModalVisible} id={""} disablePersonalTasks />
      </ModalRenderWithCondition>

      {/* Open task detailed page */}
      <RenderWithCondition condition={isDetailedTaskViewModalVisible}>
        <ModalTask
          isVisible={isDetailedTaskViewModalVisible}
          setIsVisible={setIsDetailedTaskViewModalVisible}
          id={currenDetailedTaskId.current}
        />
      </RenderWithCondition>
    </ModalUI>
  );
};

const style = {
  header: {
    display: "flex",
    borderBottom: "1px solid #000000",
    padding: 9,
  },
  title: {
    width: "33%",
    display: "flex",
    justifyContent: "center",
  },
  main: {
    paddingTop: 20,
    paddingRight: 15,
    paddingLeft: 9,
  },
  searchInput: {
    flex: 3,
    display: "flex",
    "border-radius": 10,
    "background-color": "#ECF0EF",
    "padding-left": 8,
    "padding-right": 8,
    "padding-top": 11,
    "padding-bottom": 11,
    "align-items": "center",
  },
  searchButton: {
    flex: 1,
    marginLeft: 14,
  },
  searchButtonText: {
    color: Colors.LIGHT.text.accent,
    fontSize: 16,
    fontWeight: "400",
  },
  mobContainer: {
    height: "100%",
    backgroundColor: Colors.LIGHT.background.main,
    marginTop: 10,
  },
  createTaskButtonText: {
    color: Colors.LIGHT.text.grey,
    fontSize: 18,
    fontWeight: 400,
    marginTop: 20,
  },
  loaderContainer: {
    display: "flex",
    "justify-content": "center",
    width: "90%",
    position: "absolute",
  },
};

const Input = styled.input`
  margin-left: 6px;
  width: 100%;
  ::placeholder {
    color: rgba(60, 60, 67, 0.6);
  }
`;
