import React, { FC, memo, MouseEvent, useMemo, useState, useCallback } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ModalMeetStatus } from "@components/shared/calendar/events/ModalMeetStatus";
import useConfigContext from "@components/shared/calendar/hooks/useConfigContext";
import { RenderWithCondition } from "@hoc";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import { close, TaskSubtypes, updateTaskId } from "@store/businessTask";
import { get as getTask } from "@store/businessTask";
import { setIsPersonalTask } from "@store/businessTask/slice";
import { selectScreenDay, updateId } from "@store/screenDay";
import { setOpenContextData } from "@store/screenDay/slice";
import { selectTheme } from "@store/theme";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TaskActions, TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";

import { ModalClose } from "../../../ListTasks/components/modalClose";
import { ModalDelegate } from "../../../ListTasks/components/modalDelegate";

import { configEvent, HEIGHT_ICON } from "./config";

const schema = Colors.LIGHT;

interface IWeekProps {
  disableOffset: boolean;
  weekStyles: boolean;
  showAsStrip: boolean;
  isCompactView: boolean;
  reduceEventTextWidth: boolean;
}

interface IProps extends IEventOfDaySimplified {
  hour: number;
  index: number;
  press?: (arg: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  startHour: number;
  columns?: Array<number>;
  deep: number;
  heightBlock?: number;
  inSide: number;
  width: number;
  tagName?: string;
  columnsNumber: number;
  weekViewSettings?: IWeekProps;
  isLight?: boolean;
  setToScroll?: (arg: number) => void;
  mouseRightClick?: (e: MouseEvent<HTMLButtonElement>, data: IEventOfDaySimplified) => void;

  currentMeetId?: string;
  position?: {
    left: number;
    top: number;
  };
  data?: IEventOfDaySimplified;
}

const WIDTH_TEXT_TIME = 50;

export const Event: FC<IProps> = memo(function Event(event: IProps) {
  const {
    startTime,
    endTime,
    type,
    width,
    tag,
    name,
    hour,
    heightBlock = 36,
    id,
    press,
    deep,
    columns,
    weekViewSettings = {},
    isLight,
    mouseRightClick,
    currentMeetId,
    position,
  } = event;
  const [isVisibleComment, setVisibleComment] = useState<string | null>(null);

  const { disableOffset = false } = weekViewSettings as IWeekProps;
  const eventStartHour = dayjs(startTime).hour();
  const eventStartMinute = dayjs(startTime).minute();
  const colorSchema = selectTheme("extra");
  const dispatch = useDispatch();

  const pressHandler = () => {
    if (event.type === "MEETING") {
      dispatch(updateId({ id: event.id, modalOpen: true }));
    }

    if (event.type === "TASK") {
      dispatch(updateTaskId(true));
      dispatch(setIsPersonalTask(event.taskType === "PERSONAL"));
      dispatch(getTask({ id: event.taskId, isPersonalTask: false }));
    }
  };

  function getViewObjectFunction() {
    if (isEmpty(weekViewSettings)) {
      return () => handleEventPress();
    }
    return () => pressHandler();
  }

  const { contextConfig, statusToSet } = useConfigContext(event, getViewObjectFunction(), setVisibleComment);

  const { openContextData } = useSelector(selectScreenDay);

  const { contextTask } = useSelector(selectAttachedEvents);
  const isOpenContext = openContextData?.id === event?.id && openContextData?.startTime === event?.startTime && position.top !== 0;

  const diff = dayjs(endTime).diff(dayjs(startTime), "minutes");
  const diffHours = Math.floor(diff / 60);
  const diffMinutes = diff - diffHours * 60;

  const hourBlock = heightBlock * diffHours + heightBlock * (diffMinutes / 60) + Math.round(diffHours * 2.2);
  const eventDeep = useMemo(() => (deep ? deep : 0), [event]);

  const offsetTop = useMemo(() => heightBlock * (eventStartMinute / 60), [eventStartMinute, heightBlock]);
  const widthBlock = useMemo(() => (columns?.length ? width / (12 / columns.length) : width), [columns?.length, width]);

  const calculatedWidthTextTime = disableOffset ? 0 : WIDTH_TEXT_TIME;
  const offsetLeft = useMemo(
    () => (columns?.length ? calculatedWidthTextTime + (width / 12) * (columns[0] - 1) : calculatedWidthTextTime),
    [calculatedWidthTextTime, columns, width],
  );

  const meetNonConfirmedAttendance = useMemo(() => event.type === "MEETING" && event?.currentParticipantStatus === null, [event]);
  const meetWithTypeAll = useMemo(() => !isEmpty(event.presetGroupIds), [event]);
  const meetMayBeAttendance = useMemo(
    () => event.type === "MEETING" && event?.currentParticipantStatus === "MAYBE_ATTENDER",
    [event?.currentParticipantStatus, event.type],
  );
  const isPersonalTask = useMemo(() => event.type === "TASK" && event.taskType === TaskSubtypes.Personal, [event]);

  const configColor: { text: string; bg: string; border?: string; opacity?: number } = useMemo(() => {
    if (event.currentParticipantMeetingStatus === "DID_NOT_TAKE_PLACE" || event.currentParticipantMeetingStatus === "WILL_NOT_TAKE_PLACE")
      return { text: schema.yellow, bg: schema.lightYellow };
    if (!event.active) return { text: schema.red, bg: schema.lightRed };
    if (meetWithTypeAll) return { text: configEvent(event.deep)[type]?.textColor, bg: configEvent()[type]?.backgroundColor };
    if (meetNonConfirmedAttendance) return { text: schema.text.main, bg: schema.white, border: schema.blue };
    if (meetMayBeAttendance) return { text: schema.white, bg: schema.lightBlue, border: schema.lightBlue };
    if (isPersonalTask) return { text: schema.white, bg: schema.darkPurple, opacity: 1 };
    return { bg: configEvent()[type]?.backgroundColor, text: configEvent(event.deep)[type]?.textColor };
  }, [
    event.active,
    event.currentParticipantMeetingStatus,
    event.deep,
    isPersonalTask,
    meetMayBeAttendance,
    meetNonConfirmedAttendance,
    type,
  ]);
  const smallBlockToSize = hourBlock < 14 ? hourBlock : 14;

  const handleEventPress = useCallback(() => {
    press?.(
      { id: event?.taskId ? event.taskId : id, repeat: Boolean(event?.repeat), date: event.startTime },
      type ?? "MEETING",
      event.taskType === "PERSONAL",
    );
  }, []);

  const closeTask = useCallback((reason: string, comment: string) => {
    dispatch(setOpenContextData(null));
    dispatch(close({ amount: { id: event.taskId, reason, comment }, isPersonalTask }));
  }, []);

  if (hour !== eventStartHour) return null;

  return (
    <>
      <button
        id={isLight && "event_lighting"}
        style={{
          ...styles.meet,
          ...{
            top: offsetTop + 1,
            height: hourBlock,
            width: widthBlock - eventDeep * 3,
            left: offsetLeft + eventDeep * 5,
            backgroundColor: currentMeetId === id ? colorSchema.lighGrey : configColor.bg,
            zIndex: eventDeep + 3,
            opacity: 0.7,
            position: "absolute",
          },
          ...(configColor.border && { border: `0.7px solid ${configColor.border}` }),
          ...(configColor.opacity && { opacity: configColor.opacity }),
          ...(isLight === true && styles.lightShadow),
          ...(isLight === false && { opacity: 0.3 }),
        }}
        onClick={handleEventPress}
        disabled={isLight === false}
        onContextMenu={(e) => mouseRightClick && !event.externalId && mouseRightClick(e, event)}
      >
        <div style={{ ...styles.row, height: "100%" }}>
          <div
            style={{
              ...styles.line,
              backgroundColor: configColor.text,
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: hourBlock < 14 ? hourBlock - 2 : 12,
              paddingTop: hourBlock < HEIGHT_ICON ? 0 : "1%",
              flex: 1,
              overflow: "hidden",
            }}
          >
            {
              configEvent(
                hourBlock < HEIGHT_ICON ? hourBlock : HEIGHT_ICON,
                configColor.text,
                Number(event.numberOfParticipants) > 1 || meetWithTypeAll,
              )[type]?.icon
            }
            <RenderWithCondition condition={tag}>
              <TextFont
                color={configColor.text}
                style={{
                  fontWeight: "bold",
                  width: "auto",
                  lineHeight: `${smallBlockToSize}px`,
                  fontSize: smallBlockToSize,
                  height: hourBlock < 13 ? hourBlock : 15,
                }}
              >{`${tag?.name} `}</TextFont>
            </RenderWithCondition>
            <TextFont
              color={configColor.text}
              style={{
                flex: 1,
                ...styles.text,
                ...commonStyles.oneOfLines,
                lineHeight: `${smallBlockToSize}px`,
                fontSize: smallBlockToSize,
                height: hourBlock < 13 ? hourBlock : 15,
              }}
            >
              {name}
            </TextFont>
          </div>
        </div>
      </button>
      <RenderWithCondition condition={isOpenContext && !isVisibleComment}>
        <TaskActions pos={position} configuration={contextConfig} isForMeeting />
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenContext}>
        <ModalMeetStatus
          onClose={() => {
            setVisibleComment(null);
            dispatch(setOpenContextData(null));
          }}
          isVisible={isVisibleComment === "status"}
          onSend={() => {
            dispatch(setOpenContextData(null));
          }}
          statusToSet={statusToSet}
          event={event}
        />
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenContext && isVisibleComment === "close"}>
        <div onClick={(e) => e.stopPropagation()}>
          <ModalClose
            isVisible={isVisibleComment === "close"}
            setIsVisible={() => {
              setVisibleComment(null);
              dispatch(setOpenContextData(null));
            }}
            onSave={closeTask}
          />
        </div>
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenContext && isVisibleComment === "delegate"}>
        <div onClick={(e) => e.stopPropagation()}>
          <ModalDelegate
            close={() => {
              setVisibleComment(null);
              dispatch(setOpenContextData(null));
            }}
            isModal={true}
            task={contextTask}
          />
        </div>
      </RenderWithCondition>
    </>
  );
}, arePropsEqual);

const styles = {
  meet: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRadius: 7,
    paddingLeft: 0,
  },
  line: {
    height: "99%",
    width: 3,
    marginRight: 3,
    marginLeft: 0.5,
    marginTop: 0.5,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    borderRadius: 7,
  },
  text: {
    marginLeft: 5,
    fontWeight: "400",
    textOverflow: "ellipsis",
  },
  lightShadow: {
    boxShadow: "0 4px 11px rgba(81, 67, 122, 0.5)",
    borderColor: Colors.LIGHT.blue,
    borderWidth: 1.5,
  },
};
