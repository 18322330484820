import React, { FC, useMemo } from "react";

import dayjs from "dayjs";

import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { isTablet } from "@utils/index";

import { BORDER_WIDTH, HEIGHT_BLOCK, WIDTH_BLOCK } from "../constants";

interface IProps {
  events: IEventsOfDay[];
}

export const RowParticipant: FC<IProps> = ({ events }) => (
  <div style={{ ...styles.container, position: "relative" }}>
    {events.map((event) => (
      <Event key={event.id} event={event} />
    ))}
  </div>
);

const Event = ({ event }: { event: IEventsOfDay }) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width = widthMinute * dayjs(event.endTime).diff(event.startTime, "minutes");
    const left = dayjs(event.startTime).hour() * WIDTH_BLOCK + widthMinute * dayjs(event.startTime).minute();
    const color = "#2979524D";

    return { width, left, color };
  }, [event.startTime, event.endTime, event.type]);

  return <div style={{ ...styles.event, position: "absolute", width: params.width, left: params.left, backgroundColor: params.color }} />;
};

const styles = {
  container: {
    display: "flex",
    height: HEIGHT_BLOCK + BORDER_WIDTH,
    width: "100%",
  },
  event: {
    top: isTablet ? 8 : 0,
    bottom: 0,
    zIndex: 1,
  },
};
