import React, { useState, useRef } from "react";

import { useSelector } from "react-redux";

import { LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useGetContainerDimensions } from "@hooks/useGetContainerDimensions";
import { IStateUser } from "@interfaces/user.interface";
import { Layout } from "@layout";
import { selectUserForAssistant, userSelector } from "@store/user";
import { ModalUI } from "@ui";

import { Home } from "./components";
import { TypeTab } from "./types";
import { useConfigTabs } from "./useConfigTabs";

export const Profile = () => {
  const { isTablet } = useDimension();

  const [activeTab, setActiveTab] = useState<TypeTab>(isTablet ? "devices" : "");
  const containerRef = useRef<HTMLDivElement>(null);

  const { isLoading, directorId } = userSelector();
  const directorUser = useSelector(selectUserForAssistant);

  const { tab } = useConfigTabs({ activeTab, setTab: setActiveTab });

  const mob = () => (
    <section style={{ height: "100%" }}>
      <Home user={directorUser as IStateUser} setActiveTab={setActiveTab} isAssistant={!!directorId} />
      <ModalRenderWithCondition condition={Boolean(activeTab)}>
        <ModalUI isVisible={!!activeTab} onClose={() => setActiveTab("")}>
          {tab}
        </ModalUI>
      </ModalRenderWithCondition>
    </section>
  );

  const tablet = () => (
    <div style={styles.container}>
      <div style={styles.leftSide}>
        <Home user={directorUser as IStateUser} setActiveTab={setActiveTab} isAssistant={!!directorId} />
      </div>
      <div style={styles.rightSide}>{tab}</div>
    </div>
  );

  return (
    <Layout headerHeight="0%" mainStyle={{ paddingLeft: 5 }} backgroundColor="#fff">
      <div ref={containerRef} style={{ height: "100%", width: "100%" }}>
        <LoaderRenderWithCondition condition={isLoading} parent={containerRef.current}>
          {isTablet ? tablet() : mob()}
        </LoaderRenderWithCondition>
      </div>
    </Layout>
  );
};

const styles = {
  container: {
    display: "flex",
    height: "100%",
  },
  leftSide: {
    width: "33%",
    marginRight: "2%",
  },
  rightSide: {
    width: "65%",
    backgroundColor: "#F5F9F8",
  },
};
