import React, { ChangeEvent, FC, memo, useEffect, useMemo, useState, MouseEvent, useCallback } from "react";

import { styled } from "styled-components";

import { ReactComponent as SvgCheck } from "@assets/check.svg";
import { ReactComponent as SvgPeopleWrapper } from "@assets/people_wrapper.svg";
import { RenderWithCondition } from "@hoc";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { IExternalUser } from "@services/meet.service";
import { userAvatarStyles } from "@shared/userAvatar";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { userSelector } from "@store/user";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, Checkbox } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import globalState from "@utils/globalState";

interface IProps {
  item: IStateUser | IExternalUser;
  setSelectUser?: (arg: IStateUser | undefined) => void;
  onSave?: (user: (IStateUser | IExternalUser)[], isExternalUser?: boolean) => void;
  onPress?: (user: IStateUser | IExternalUser) => void;
  onRightClick?: (arg1: MouseEvent<HTMLButtonElement>, item: IStateUser | IExternalUser) => void;
  isRenderImg?: boolean;
  isRenderCheckbox?: boolean;
  isAdded?: boolean | undefined;
  isBusy?: boolean;
  hover?: string;
  isUserHasEditPermissions?: boolean;
  isUserHasInvitePermissions?: boolean;
  meetOwnerId?: string;
  isDeletable: boolean;
}

export const Item: FC<IProps> = memo(function Item({
  item,
  setSelectUser,
  hover,
  onSave,
  onPress,
  onRightClick,
  isBusy = false,
  isRenderImg = true,
  isRenderCheckbox = true,
  isAdded = false,
  meetOwnerId,
  isUserHasEditPermissions = false,
  isUserHasInvitePermissions = false,
  isDeletable,
}) {
  const [isItemChecked, setIsItemChecked] = useState<boolean>(isAdded);
  const [isCheckedByCurrentUser, setIsCheckedByCurrentUser] = useState(false);
  const isExternalUser =
    !Object.prototype.hasOwnProperty.call(item, "avatarFileId") || !Object.prototype.hasOwnProperty.call(item, "workPosition");
  const { currentUser, directorId } = userSelector();

  useEffect(() => {
    setIsItemChecked(isAdded);
  }, [isAdded]);

  const isCheckboxDisabled = useMemo(() => {
    const isItMe = item.id === meetOwnerId || (item.id === currentUser?.id && !directorId);
    const isItemAddedByMe = isDeletable || isCheckedByCurrentUser;

    if (isItMe || (!isItemAddedByMe && isAdded && !isUserHasEditPermissions)) return true;

    return false;
  }, [
    item.id,
    meetOwnerId,
    currentUser?.id,
    directorId,
    isUserHasInvitePermissions,
    isUserHasEditPermissions,
    isAdded,
    isCheckedByCurrentUser,
    isDeletable,
  ]);
  const isEditableExternalUser = useMemo(() => item.userId === currentUser?.id && !isDeletable, [item, currentUser?.id, isDeletable]);

  const handleTaskCheckBox = useCallback(
    (item: IStateUser | IExternalUser) => {
      if (isCheckboxDisabled) return;

      setIsCheckedByCurrentUser((prev) => !prev);

      onPress?.(item);

      onSave?.(item, isExternalUser);
    },
    [isCheckboxDisabled, isExternalUser, onPress, onSave],
  );

  const pressImg = (e: ChangeEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (setSelectUser) {
      setSelectUser(item);
    }
  };

  const borderColorStatus = useMemo(() => (item?.busy ? globalState.colorSchema.red : globalState.colorSchema.green), [item?.busy]);

  const renderName = (user: IStateUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

  const isGroupAll = item?.type === "ALL_IN_SPACE";

  return (
    <Button
      hover={hover}
      onClick={() => handleTaskCheckBox(item)}
      disabled={isCheckboxDisabled || isGroupAll}
      onContextMenu={(e) => isEditableExternalUser && onRightClick(e, item)}
    >
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <RenderWithCondition condition={isRenderImg && !isExternalUser}>
          <button onClick={pressImg}>
            <UserAvatar
              avatarFileId={item.avatarFileId}
              style={{
                ...userAvatarStyles.userImage,
                ...(isAdded && { opacity: 0.5 }),
                ...(isBusy && { border: `2px solid ${borderColorStatus}` }),
                ...(isItemChecked && { opacity: 0.5 }),
              }}
            />
          </button>
        </RenderWithCondition>

        <RenderWithCondition condition={isExternalUser && !isGroupAll}>
          <LetterAvatar userName={item?.firstName && item?.firstName[0]?.toUpperCase()} translucent={isItemChecked} />
        </RenderWithCondition>
        <RenderWithCondition condition={isGroupAll}>
          <IconWrap>
            <SvgPeopleWrapper fill={Colors.LIGHT.orange} />
          </IconWrap>
        </RenderWithCondition>

        <div style={{ flex: 1, overflow: "hidden" }}>
          <TextFont
            style={{
              ...userAvatarStyles.userTitle,
              ...(isItemChecked && { color: globalState.colorSchema.grey }),
              ...((item as IExternalUser).deleted && { textDecoration: "line-through" }),
            }}
          >
            {isGroupAll ? 'Выбрана группа "ВСЕ"' : renderName(item)}
          </TextFont>

          <RenderWithCondition condition={item?.workPosition || item.email}>
            <TextFont style={{ fontSize: 14, color: "#B3BEB8", ...commonStyles.oneOfLines }}>
              {isExternalUser ? item.email : item?.workPosition}
            </TextFont>
          </RenderWithCondition>
        </div>

        <RenderWithCondition condition={isRenderCheckbox && !isGroupAll}>
          <Checkbox onChange={() => handleTaskCheckBox(item)} isChecked={isItemChecked} disabled={isCheckboxDisabled} />
        </RenderWithCondition>
      </div>
      <RenderWithCondition condition={isAdded && !isRenderImg}>
        <SvgCheck />
      </RenderWithCondition>
    </Button>
  );
},
arePropsEqual);

const Button = styled.button<{ hover: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  padding: 8px 12px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;

  &:hover {
    ${(props) => props.hover && { "background-color": props.hover }}
  }
`;
const IconWrap = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
