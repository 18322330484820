import React, { FC, memo, useMemo } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgCancel } from "@assets/cancel.svg";
import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { ReactComponent as SvgOutlook } from "@assets/outlook.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  meet: IMeet;
  statusParticipant?: IMeetParticipants;
  pressDotes: () => void;
  isRenderDotes: boolean;
}

export const ViewSaveHeader: FC<IProps> = memo(function ViewSaveHeader({ meet, statusParticipant, pressDotes, isRenderDotes }) {
  const meetingStatus = useMemo(() => {
    if (!meet.active) return "canceled";
    if (statusParticipant?.meetingStatus === "DID_NOT_TAKE_PLACE") return "didNotTake";
    if (statusParticipant?.meetingStatus === "WILL_NOT_TAKE_PLACE") return "willNotTake";

    return null;
  }, [meet.active, statusParticipant?.meetingStatus]);

  const config: Record<"canceled" | "didNotTake" | "willNotTake" | "outlook", { title: string; icon: JSX.Element; colorText: string }> = {
    canceled: { title: "statusCancelForAll", icon: <SvgCancel width={17} height={17} style={styles.icon} />, colorText: Colors.LIGHT.red },
    didNotTake: {
      title: "statusTookPlaceNo",
      icon: <SvgNotTake width={17} height={17} style={styles.icon} fill={Colors.LIGHT.yellow} />,
      colorText: Colors.LIGHT.yellow,
    },
    willNotTake: {
      title: "statusTakePlaceNo",
      icon: <SvgNotTake width={17} height={17} style={styles.icon} fill={Colors.LIGHT.yellow} />,
      colorText: Colors.LIGHT.yellow,
    },
    outlook: {
      title: "Событие из Outlook",
      icon: <SvgOutlook width={17} height={17} style={styles.icon} fill={Colors.LIGHT.blue} />,
      colorText: Colors.LIGHT.blue,
    },
  };

  const isEditAllGroup = useMemo(() => {
    const isGroupAll = meet?.presetGroups?.length && meet?.presetGroups?.[0]?.type === "ALL_IN_SPACE";
    const isOwner = meet.currentParticipantStatus === "OWNER";
    return !(isGroupAll && !isOwner);
  }, [meet]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {meet.externalId && (
          <div style={styles.iconWrapper}>
            {config["outlook"].icon}
            <TextFont style={{ ...styles.iconText, color: config["outlook"]?.colorText }}>
              <FormattedMessage id={config["outlook"]?.title} />
            </TextFont>
          </div>
        )}
        <RenderWithCondition condition={meetingStatus}>
          <div style={styles.iconWrapper}>
            {meetingStatus && config[meetingStatus].icon}
            <TextFont style={{ ...styles.iconText, color: config[meetingStatus]?.colorText }}>
              <FormattedMessage id={config[meetingStatus]?.title} />
            </TextFont>
          </div>
        </RenderWithCondition>
      </div>
      <RenderWithCondition condition={!meetingStatus && isRenderDotes && isEditAllGroup}>
        <button style={{ alignSelf: "flex-start" }} onClick={pressDotes}>
          <SvgDotes fill={"white"} />
        </button>
      </RenderWithCondition>
    </div>
  );
});

const styles = {
  iconWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 9px",
    borderRadius: 55,
    alignItems: "center",
    alignSelf: "flex-start",
    backgroundColor: Colors.LIGHT.white,
    marginBottom: 6,
    marginRight: 10,
  },
  icon: {
    marginRight: 5,
  },
  iconText: {
    fontSize: 14,
    color: Colors.LIGHT.green,
  },
};
