import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IAddNewParticipants, IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { api, StoreTagTypes } from "@services/api";
import {
  useAddParticipantsToSingleOrSerieMeetingMutation,
  useGetCommonMeetingSlotQuery,
  useGetMeetingAttendanceQuery,
  useUpdateSingleOrSerieMeetingMutation,
} from "@services/meetApi";
import { Calendar } from "@shared/planningWork";
import { resetExternalUsers } from "@store/externalUsers/slice";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { EditTimerOptions } from "@ui/TimePicker";
import { calendar, isTablet, toFormatDate, toFormatHHmm, toFormatISO } from "@utils";
import globalState from "@utils/globalState";

import { notifyParticipantsTextIdOptions } from "../constants";

import { Activity, TabletParticpants, Time } from "./components";
import { useData } from "./useData";

export interface IStartData {
  data: IMeet;
  handleTime: (type: "startTime" | "endTime", arg: any) => void;
  startTime: string;
  endTime: string;
  handleData: (arg: IMeet) => void;
}
interface IProps {
  close: () => void;
  startData: IStartData;
  isUserHasEditPermissions: boolean;
  hotUpdate?: boolean;
}

export const ActivityParticipants: FC<IProps> = ({ close, startData, hotUpdate, isUserHasEditPermissions }) => {
  const { directorId } = userSelector();
  const selectedDay = useSelector(selectCurrentDate);
  const dispatch = useDispatch();

  const {
    startTime,
    endTime,
    data,
    handleData,
    handleTime,
    formatData,
    isVisbleSetCommonSlot,
    setIsVisbleSetCommonSlot,
    pressCommonSlot,
    handleSetTimeFromCommonSlot,
    isEdit,
    isVisbleCalendar,
    setIsVisbleCalendar,
    participantLight,
    setParticipantLight,
    meetingDurationMins,
    commonSlot,
  } = useData(startData);
  const meetChangeMode = useRef<"serie" | "event">();

  const [isVisibleNotificationOptions, setIsVisibleNotificationOptions] = useState(false);
  const [isChangeModeSelectorVisible, setIsChangeModeSelectorVisible] = useState(false);
  const [isGroupAll, setGroupAll] = useState(false);
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs(startTime));
  const initialParticipantsIds = useMemo(() => startData?.data?.participants?.map((item) => item.userId) ?? [], [startData.data]);
  const initialExternalParticipantsIds = useMemo(() => startData?.data?.externalUsers?.map((item) => item.id) ?? [], [startData.data]);

  const [deletedExternalUsersIds, setDeletedExternalUsersIds] = useState<string[]>([]);

  const [updateSingleMeetOrSerie] = useUpdateSingleOrSerieMeetingMutation();
  const [addParticipantsToSingleOrSerieMeeting] = useAddParticipantsToSingleOrSerieMeetingMutation();

  const { data: participantsAttendance } = useGetMeetingAttendanceQuery({
    startTime: toFormatISO(dayjs(startTime).date(currentDate.date())),
    endTime: toFormatISO(dayjs(endTime).date(currentDate.date())),
    userIds: data.participants?.map((i) => i.userId) ?? [],
    excludedMeetingId: data?.id ?? "",
  });

  const shouldUpdateMeetBody = useRef(true);
  const isDaysDiffer = useRef(false);
  const isParticipantsChanged = useRef(false);
  const isExternalParticipantsChanged = useRef(false);

  useEffect(() => {
    if (isGroupAll) {
      handleSave();
    }
  }, [isGroupAll]);

  const handleUserChoice = (mode: "serie" | "event") => {
    meetChangeMode.current = mode;
    setIsChangeModeSelectorVisible(false);
    handleSave();
  };

  const pressSave = () => {
    if (!hotUpdate) {
      return handleSave();
    }

    if (data.repeat || data.parentEvent) {
      setIsChangeModeSelectorVisible(true);
    }

    if (!data.repeat && !data.parentEvent) {
      return handleSave();
    }
  };

  const handleSave = async () => {
    if (!data.createdByCurrentUser) {
      const changedParticipantsIds = data?.participants?.map((item) => item.userId) ?? [];
      const changedExternalParticipantsIds = data?.externalUsers?.map((item) => item.id) ?? [];

      isParticipantsChanged.current = !isEqual(changedParticipantsIds, initialParticipantsIds);
      isExternalParticipantsChanged.current = !isEqual(changedExternalParticipantsIds, initialExternalParticipantsIds);

      const isParticipantWasDeleted =
        initialParticipantsIds.some((item) => !changedParticipantsIds.includes(item)) ||
        initialExternalParticipantsIds.some((item) => !changedExternalParticipantsIds.includes(item));

      const isTimeChanged = startTime !== startData.data.startTime || endTime !== startData.data.endTime;

      shouldUpdateMeetBody.current = (isTimeChanged || isParticipantWasDeleted) && isUserHasEditPermissions;
      isDaysDiffer.current = dayjs(startTime).format("yyyy-mm-dd") !== dayjs(startData.data.startTime).format("yyyy-mm-dd");

      if (isParticipantWasDeleted || isParticipantsChanged.current) {
        setIsVisibleNotificationOptions(true);
        return;
      }
    }

    proceedHandleSave(false);
  };

  const proceedHandleSave = async (shouldNotifyUsers: boolean) => {
    const dataCopy = {
      startTime,
      endTime,
      participants: data.participants,
      externalUsers: data.externalUsers,
    } as Partial<IMeet>;

    const newParticipantsIds: string[] = [];
    const newExternalParticipantsIds: string[] = [];

    if (!data.createdByCurrentUser && (isParticipantsChanged.current || isExternalParticipantsChanged.current)) {
      const filteredDataParticipants = [];
      const filteredExternalParticipants = [];

      for (const item of data?.participants ?? []) {
        if (initialParticipantsIds.includes(item.userId)) {
          filteredDataParticipants.push(item);
        } else {
          newParticipantsIds.push(item.userId);
        }
      }

      for (const item of data?.externalUsers ?? []) {
        if (initialExternalParticipantsIds.includes(item.id)) {
          filteredExternalParticipants.push(item);
        } else {
          newExternalParticipantsIds.push(item.id);
        }
      }

      dataCopy.participants = filteredDataParticipants;
      dataCopy.externalUsers = filteredExternalParticipants;
    }

    if (hotUpdate) {
      let targetEventId = data.id;

      if (meetChangeMode.current === "serie" && data?.parentEvent?.id) {
        targetEventId = data?.parentEvent?.id;
      }

      if (shouldUpdateMeetBody.current) {
        updateSingleMeetOrSerie({
          id: targetEventId,
          data: {
            ...dataCopy,
            participants: dataCopy.participants?.map((i) => ({
              id: i.id,
              userId: i.userId,
              status: i.status,
            })) as IMeetParticipants[],
            notifyAllParticipants: shouldNotifyUsers,
          },
          date: dayjs(selectedDay).format("YYYY-MM-DD"),
          changeSerie: meetChangeMode.current === "serie",
          repeat: Boolean(data.repeat),
          parentEvent: data.parentEvent,
          skipOptimisticUpdate: true,
        });
      }

      if (!data.createdByCurrentUser && (newParticipantsIds.length || newExternalParticipantsIds.length)) {
        const body: IAddNewParticipants = {
          ...(newParticipantsIds.length && { userIds: newParticipantsIds }),
          ...(newExternalParticipantsIds.length && { externalUserIds: newExternalParticipantsIds }),
          notifyAllParticipants: shouldNotifyUsers,
        };

        addParticipantsToSingleOrSerieMeeting({
          id: targetEventId,
          data: body,
          repeat: Boolean(data.repeat),
          parentEvent: data.parentEvent,
          date: dayjs(selectedDay).format("YYYY-MM-DD"),
          userId: directorId,
          changeSerie: meetChangeMode.current === "serie",
        });
      }
    }

    startData.handleData(data);
    startData.handleTime("startTime", startTime);
    startData.handleTime("endTime", endTime);

    if (deletedExternalUsersIds.length) {
      dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: startData.data.id }]));
    }

    close();
  };

  const handleDate = (dateWeek: Dayjs) => {
    const day = dateWeek.date();
    const month = dateWeek.month();
    const year = dateWeek.year();
    const tempStartTime = toFormatISO(dayjs(startTime).date(day).month(month).year(year));

    handleTime("startTime", tempStartTime);
    handleTime("endTime", toFormatISO(dayjs(endTime).date(day).month(month).year(year)));
    setCurrentDate(dayjs(tempStartTime));
    setIsVisbleCalendar(false);
  };

  const handleHourSlotPress = (hour: number) => {
    if (hour === 24 || !isUserHasEditPermissions) return;

    const selectedDateTime = dayjs(currentDate.format("YYYY-MM-DD")).set("hour", hour);
    let calculatedEndTime = selectedDateTime.add(meetingDurationMins, "minutes");
    const updatedStartTime = toFormatISO(selectedDateTime);

    if (calculatedEndTime.isAfter(selectedDateTime, "day") && meetingDurationMins > 55) {
      calculatedEndTime = dayjs(selectedDateTime).endOf("day");
    }

    const updatedEndTime = toFormatISO(calculatedEndTime);

    handleTime("startTime", updatedStartTime);
    handleTime("endTime", updatedEndTime);
  };

  const closeWrapper = () => {
    dispatch(resetExternalUsers());

    if (deletedExternalUsersIds.length) {
      dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: startData.data.id }]));
    }
    close();
  };

  const render = {
    commonSlot:
      commonSlot?.endTime && commonSlot?.startTime ? (
        <div style={styles.wrapperSlot}>
          <TextFont size={16} weight="700" type="bold">
            <FormattedMessage id="nearestСommonSlot" />
          </TextFont>
          <button onClick={pressCommonSlot} style={styles.commonSlot}>
            <TextFont size={16}>{`${dayjs(commonSlot?.startTime).format("DD.MM.YY")}, ${calendar
              .getWeekDayRu(dayjs(commonSlot?.startTime).day())
              ?.toLowerCase()} ${toFormatHHmm(dayjs(commonSlot?.startTime))}`}</TextFont>
          </button>
        </div>
      ) : (
        <TextFont style={styles.wrapperSlot} size={16} type="bold">
          <FormattedMessage id="commonSlotNotFound" />
        </TextFont>
      ),
    activity: (
      <RenderWithCondition condition={data.participants?.length}>
        <Activity
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          participantLight={participantLight}
          participants={data.participants}
          time={{ startTime, endTime }}
          handleHourSlotPress={handleHourSlotPress}
          meetId={data?.id}
        />
      </RenderWithCondition>
    ),
  };

  const renderBtnHeader = (type: "save" | "cancel") => {
    const config = {
      save: {
        bg: Colors.LIGHT.background.green,
        color: Colors.LIGHT.white,
        press: pressSave,
      },
      cancel: {
        bg: Colors.LIGHT.lighGrey,
        color: Colors.LIGHT.text.grey,
        press: closeWrapper,
      },
    };

    return (
      <div style={{ justifySelf: type === "cancel" ? "left" : "right", padding: "0 20px" }}>
        <button style={{ ...styles.headerBtn, backgroundColor: config[type].bg }} onClick={config[type].press}>
          <TextFont color={config[type].color} size={16} weight="700">
            <FormattedMessage id={type} />
          </TextFont>
        </button>
      </div>
    );
  };

  return (
    <ModalUI id="modalActivityParticipant" isFullWidth={isTablet} scrollEnable={false} onClose={closeWrapper} isVisible>
      <HeaderModal
        title="membersEmployment"
        isBorder={false}
        isCancel={false}
        isEdit={isEdit}
        onSave={pressSave}
        styleTextSave={{ fontWeight: "700" }}
        onClose={closeWrapper}
        closeElement={renderBtnHeader("cancel")}
        saveElement={renderBtnHeader("save")}
      />

      <div style={styles.container}>
        <div style={styles.date}>
          <button
            style={{ ...commonStyles.blockDate, ...styles.btnDate }}
            onClick={() => isUserHasEditPermissions && setIsVisbleCalendar(!isVisbleCalendar)}
          >
            <TextFont size={16}>{`${dayjs(startTime).format("DD.MM.YY")}, ${calendar
              .getWeekDayRu(dayjs(startTime).day())
              ?.toLowerCase()}`}</TextFont>
          </button>
          <div style={styles.time}>
            <Time value={startTime} handleTime={handleTime} type={EditTimerOptions.StartTime} disablePress={!isUserHasEditPermissions} />
            <Time value={endTime} handleTime={handleTime} type={EditTimerOptions.EndTime} disablePress={!isUserHasEditPermissions} />
          </div>
        </div>
        <ModalRenderWithCondition condition={isVisbleCalendar}>
          <Calendar
            currentDay={dayjs(startTime).format("YYYY-MM-DD")}
            startDay={data.date}
            press={handleDate}
            deadline={data.repeat?.endTime ? toFormatDate(dayjs(data.repeat.endTime)) : null}
            disable={{ style: false, button: false }}
          />
        </ModalRenderWithCondition>

        <div style={styles.participants}>
          <div>
            <TabletParticpants
              participantLight={participantLight}
              setDeletedExternalUsersIds={setDeletedExternalUsersIds}
              deletedExternalUsersIds={deletedExternalUsersIds}
              data={formatData}
              setParticipantLight={setParticipantLight}
              handleData={handleData}
              isUserHasEditPermissions={isUserHasEditPermissions}
              participantsAttendance={participantsAttendance ?? null}
              handleOnGroupAll={() => setGroupAll(true)}
            />
            <RenderWithCondition condition={isUserHasEditPermissions}>{render.commonSlot}</RenderWithCondition>
          </div>
          <div>{render.activity}</div>
        </div>
      </div>
      <ModalRenderWithCondition condition={isVisbleSetCommonSlot}>
        <NotificationConfirm
          phraseOkId="set"
          phraseId={"commonSlotSetup"}
          onOk={handleSetTimeFromCommonSlot}
          onCancel={() => setIsVisbleSetCommonSlot(false)}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isChangeModeSelectorVisible}>
        <NotificationConfirm
          phraseId="oneMeetOrAll"
          phraseOkId="allSeries"
          phraseCancelId="oneMeet"
          onOk={() => handleUserChoice("serie")}
          onCancel={() => handleUserChoice("event")}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleNotificationOptions}>
        <NotificationConfirm
          isOpen={isVisibleNotificationOptions}
          phraseId={notifyParticipantsTextIdOptions.titleTextId}
          phraseOkId={notifyParticipantsTextIdOptions.okTextId}
          phraseCancelId={notifyParticipantsTextIdOptions.cancelTextId}
          onOk={() => proceedHandleSave(true)}
          onCancel={() => proceedHandleSave(false)}
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const styles = {
  container: {
    padding: "0 12px",
  },
  participants: {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    gridTemplateRows: "calc(100vh - 250px)",
    gridGap: 20,
    "overflow-x": "hidden",
    "overflow-y": "auto",
    paddingBottom: "20px",
  },
  date: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    marginTop: 20,
  },
  btnDate: {
    justifyContent: "center",
    backgroundColor: "#F5F9F8",
  },
  time: {
    display: "flex",
    gap: 7,
  },
  commonSlot: {
    backgroundColor: globalState.colorSchema.lighGrey,
    border: `1px solid ${globalState.colorSchema.background.green}`,
    padding: "4px 8px",
    borderRadius: 4,
  },
  wrapperSlot: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: -1,
  },
  headerBtn: {
    height: 44,
    paddingLeft: 22,
    paddingRight: 22,
    borderRadius: 10,
  },
};
