import React, { FC, useMemo, useState } from "react";

import styled from "styled-components";

import { RenderWithCondition } from "@components/hoc";
import { SCREEN_PADDING } from "@constants/settings";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IExternalUser } from "@services/meet.service";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, SearchInput, TextFont } from "@ui";

import { useData } from "../useData";

import { Filters } from "./Filters";

const LIMIT_FOR_LIST = 50;

interface IProps {
  onClose: () => void;
  isVisible: boolean;
  participants: IMeetParticipants[];
  externalUsers: IExternalUser[];
  renderItem: (user: IExternalUser | IMeetParticipants, isModalList?: boolean) => JSX.Element;
  isAuthor: boolean;
}

export const ModalList: FC<IProps> = ({ onClose, isVisible, externalUsers, participants, renderItem, isAuthor }) => {
  const [sizeListParticipants, setSizeListParticipants] = useState(LIMIT_FOR_LIST);

  const { filter, setFilter, allParticipants, resultParticipants } = useData({
    participants: participants ?? [],
    externalUsers: externalUsers ?? [],
  });
  const [search, setSearch] = useState("");

  const matchedParticipants = resultParticipants.filter((i) => {
    const isEqual = new RegExp(search).test(
      `${(i as IMeetParticipants).user?.firstName} ${(i as IMeetParticipants).user?.middleName} ${(i as IMeetParticipants).user?.lastName}`,
    );
    return isEqual;
  });

  const isSmallSize = !(matchedParticipants?.length > sizeListParticipants);

  const currentListParticipants = useMemo(() => {
    if (isSmallSize) return matchedParticipants ?? [];
    return matchedParticipants.slice(0, sizeListParticipants) ?? [];
  }, [matchedParticipants, sizeListParticipants]);

  const declension = useMemo(() => {
    const diff = matchedParticipants.length - currentListParticipants.length;
    if (diff % 100 === 11) return "участников";
    return diff % 10 === 1 ? "участника" : "участников";
  }, [matchedParticipants, currentListParticipants]);

  const textPagination = useMemo(() => {
    const diff = matchedParticipants.length - currentListParticipants.length;
    const buttonText = diff > LIMIT_FOR_LIST ? "Показать еще 50 участников" : `Показать еще ${diff} ${declension}`;
    return "Показать еще";
  }, [matchedParticipants, currentListParticipants]);

  const handleShowMore = () => {
    setSizeListParticipants(sizeListParticipants + LIMIT_FOR_LIST);
  };
  const handleClose = () => {
    onClose();
    setSizeListParticipants(LIMIT_FOR_LIST);
  };

  return (
    <ModalUI scrollEnable={false} isVisible={isVisible} onClose={onClose}>
      <HeaderModal title="meetingMembers" onClose={handleClose} />

      <div style={styles.container}>
        <div style={{ ...SCREEN_PADDING, display: "flex", flexDirection: "column", gap: 12 }}>
          <SearchInput value={search} setValue={setSearch} isDrop />
          <Filters
            filter={filter}
            onPress={setFilter}
            allParticipants={allParticipants}
            participants={participants}
            externalUsersLength={(externalUsers ?? []).length}
            isAuthor={isAuthor}
          />
        </div>

        <div style={styles.list}>
          {currentListParticipants.map((user) => renderItem(user, true))}
          <RenderWithCondition condition={matchedParticipants.length > sizeListParticipants}>
            <WrapButtons>
              <ButtonPagination onClick={handleShowMore}>
                <TextFont size={16} color={Colors.LIGHT.grey500}>
                  {textPagination}
                </TextFont>
              </ButtonPagination>
            </WrapButtons>
          </RenderWithCondition>
        </div>
      </div>
    </ModalUI>
  );
};

const WrapButtons = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 15px;
  padding-left: 10px;
`;
const ButtonPagination = styled.button`
  display: flex;
  gap: 4px;
  padding: 4px 6px;
  align-items: center;
`;

const styles = {
  container: {
    height: "100%",
    display: "flex",
    "flex-direction": "column",
    gap: 12,
    marginTop: 12,
  },
  list: {
    maxHeight: "77%",
    display: "flex",
    "flex-direction": "column",
    "overflow-y": "auto",
    "margin-bottom": 30,
    "padding-bottom": 40,
  },
};
