import React, { memo, useEffect, useState, useMemo, useRef, useCallback } from "react";

import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ArrowDownSvg } from "@assets/arrow-down.svg";
import { ReactComponent as SvgSearchGreen } from "@assets/search-green.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { Modal } from "@screens/day/components/timetable/main/modal/Modal";
import { NotFound } from "@screens/task/components/notFound";
import { IAttachEventMeta } from "@services/meet.service";
import { getMeetingsSeries } from "@store/attachedMeetingTasks/api";
import { getSelectedItemsIds, selectAttachedEvents, selectMeetingsSeries } from "@store/attachedMeetingTasks/selectors";
import { removeSelectedItemId, removeSelectedMeetingById, resetMeetingsSeries } from "@store/attachedMeetingTasks/slice";
import { selectScreenDay } from "@store/screenDay";
import { getList, selectStoreTag } from "@store/tag";
import { Colors } from "@theme/colors";
import { EmptyBusinessTasks } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { TextFont } from "@ui/TextFont";
import EventHelperUtils from "@utils/event-helper.utills";

import { EventItem } from "./EventItem";

const eventHelperUtils = new EventHelperUtils();

interface IModalTag {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  onItemDelete: (id: string) => void;
  handleAttachItems: (itemsEntities: IMeet[], eventsMeta: IAttachEventMeta[]) => void;
}

interface ISection {
  data: IMeet[];
  day: string;
}

export const ModalMeetingAttach = memo(({ isVisible, onItemDelete, setIsVisible, handleAttachItems }: IModalTag) => {
  const dispatch = useDispatch();
  const selectedTaskIds = useSelector(getSelectedItemsIds);
  const { tags } = useSelector(selectStoreTag);
  const meetingsSeries = useSelector(selectMeetingsSeries);
  const selectedItemsIds = useSelector(getSelectedItemsIds);
  const { isLoading, errorMsg } = useSelector(selectAttachedEvents);
  const { isLoading: isLoadingMeeting } = useSelector(selectScreenDay);
  const newlySelectedEvents = useRef<IAttachEventMeta[]>([]);
  const selectedItemsEntities = useRef<IMeet[]>();
  const currenDetailedMeetingId = useRef("");
  const startDate = useRef(dayjs().format("YYYY-MM-DD"));
  const endDate = useRef(dayjs().add(6, "days").format("YYYY-MM-DD"));
  const selectMeetingSeriesQueryParams = useRef(`startDate=${startDate.current}&endDate=${endDate.current}`);
  const [isAddEventModalVisible, setIsAddEventModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [isVisibleNotFound, setIsVisibleNotFound] = useState(false);
  const phSearch = useIntl().formatMessage({ id: "search" });
  const [isDetailedMeetingViewModalVisible, setIsDetailedMeetingViewModalVisible] = useState(false);

  const fetchEvents = async () => {
    dispatch(getMeetingsSeries(selectMeetingSeriesQueryParams.current));
  };

  const openDetailedMeetingScreen = (id: string) => {
    setIsDetailedMeetingViewModalVisible(true);
    currenDetailedMeetingId.current = id;
  };

  const handleResetFilter = () => {
    startDate.current = dayjs().format("YYYY-MM-DD");
    endDate.current = dayjs(startDate.current).add(7, "days").format("YYYY-MM-DD");
    selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}`;

    setSearch("");
    dispatch(resetMeetingsSeries());
    fetchEvents();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearch(value);
    setIsVisibleNotFound(false);
    eventHelperUtils.debounce(async () => {
      startDate.current = dayjs().format("YYYY-MM-DD");
      endDate.current = dayjs(startDate.current).add(30, "days").format("YYYY-MM-DD");
      selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}&name=${value}`;

      dispatch(resetMeetingsSeries());
      fetchEvents();
    }, 1000);
  };

  const loadMoreData = useCallback(async () => {
    if (isLoading) return;

    startDate.current = dayjs(endDate.current).add(1, "days").format("YYYY-MM-DD");
    endDate.current = dayjs(startDate.current).add(30, "days").format("YYYY-MM-DD");
    const searchName = search ? `&name=${search}` : "";
    selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}${searchName}`;

    fetchEvents();
  }, [isLoading, errorMsg]);

  const isNewlySelectedEventsHasEntity = useCallback(
    (id: string, date: string) => {
      for (const item of newlySelectedEvents.current) {
        if (item.id === id && item.date === date) return true;
      }

      return false;
    },
    [newlySelectedEvents.current],
  );

  const handleSelectedItems = useCallback(
    (id: string, item: IMeet, date: string) => {
      if (isNewlySelectedEventsHasEntity(id, date)) {
        selectedItemsEntities.current = selectedItemsEntities.current?.filter((item) => item.id !== id);
        newlySelectedEvents.current = newlySelectedEvents.current?.filter((item) => item.id !== id || item.date !== date);

        dispatch(removeSelectedItemId(id));
        dispatch(removeSelectedMeetingById(id));

        return;
      }

      if (selectedTaskIds.includes(id)) {
        dispatch(removeSelectedItemId(id));
        onItemDelete(id);

        return;
      }

      if (!selectedTaskIds.includes(id) && !isNewlySelectedEventsHasEntity(id, date)) {
        selectedItemsEntities.current = [...(selectedItemsEntities.current ?? []), item];
        newlySelectedEvents.current = [...newlySelectedEvents.current, { id: item.id, date }];
      }
    },
    [selectedTaskIds],
  );

  const onAddHandler = () => {
    handleAttachItems(selectedItemsEntities.current ?? [], newlySelectedEvents.current);
    setIsVisible(false);
  };

  const checkIsChecked = useCallback(
    (id: string, date: string) => selectedItemsIds.includes(id) || isNewlySelectedEventsHasEntity(id, date),
    [selectedItemsIds, newlySelectedEvents.current],
  );

  const renderItems = useMemo(() => {
    const sectionHeaderRederer = (section: ISection) => {
      const day = section?.day;
      const sectionIndex = meetingsSeries.indexOf(section);
      const formattedDay = dayjs(day).format("DD.MM");
      const dayName = dayjs(day).format("dddd");
      const formattedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

      return (
        <p style={{ ...(sectionIndex === 0 ? { marginTop: 0 } : { marginTop: 20 }), ...style.sectionHeaderTitle }}>
          {`${formattedDay} ${formattedDayName}`}
        </p>
      );
    };

    const renderSectionData = (section: ISection) =>
      section.data.map((item) => (
        <EventItem
          item={item}
          checkbox
          handleCheckedItems={handleSelectedItems}
          isChecked={checkIsChecked(item.id, section.day)}
          date={section.day}
          onPress={() => {
            openDetailedMeetingScreen(item.id);
          }}
        />
      ));

    const renderFooter = () => (
      <button onClick={loadMoreData} style={style.showMoreContainer}>
        {isLoading ? (
          <div style={style.loaderContainer}>
            <ReactLoading type="spokes" color={Colors.LIGHT.green} height={25} width={25} />
          </div>
        ) : (
          <div style={style.loadMoreBtn}>
            <TextFont style={style.showMoreBtnText}>Еще 30 дней</TextFont>
            <ArrowDownSvg />
          </div>
        )}
      </button>
    );

    const RenderSectionList = () => (
      <>
        {meetingsSeries.map((item) => (
          <div>
            {sectionHeaderRederer(item)}
            {renderSectionData(item)}
          </div>
        ))}
        {renderFooter()}
      </>
    );

    const isEmptyArray = !meetingsSeries.length;

    if (isEmptyArray && !search.length && !isLoading) {
      return (
        <EmptyBusinessTasks
          press={() => {
            setIsAddEventModalVisible(true);
          }}
          titleTextId={"noMeetings"}
        />
      );
    } else if (isEmptyArray && !isLoading) {
      const dayStart = dayjs(startDate.current).format("DD.MM");
      const dayEnd = dayjs(endDate.current).format("DD.MM");
      const message = `В период с ${dayStart} - ${dayEnd} нет встреч удовлетворяющих поиску`;

      setTimeout(() => {
        setIsVisibleNotFound(true);
      }, 0);

      return (
        <>
          {isVisibleNotFound && (
            <>
              <NotFound handleResetFilter={handleResetFilter} disableButton titleTextId="meetingNotFound" descriptionTextId={message} />
              <button style={style.searchNext30daysContainer} onClick={loadMoreData}>
                <SvgSearchGreen />
                <TextFont style={style.searchNext30daysText}>Искать на следующие 30 дней</TextFont>
              </button>
            </>
          )}
        </>
      );
    }
    return <RenderSectionList />;
  }, [meetingsSeries, newlySelectedEvents.current, isLoading]);

  const handleClose = () => {
    dispatch(resetMeetingsSeries());
    setIsVisible(false);
  };

  useEffect(() => {
    startDate.current = dayjs().format("YYYY-MM-DD");
    endDate.current = dayjs().add(6, "days").format("YYYY-MM-DD");
    selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}`;

    if (!isLoadingMeeting) {
      dispatch(resetMeetingsSeries());
      fetchEvents();
    }
  }, [isLoadingMeeting]);

  useEffect(() => {
    if (!tags.length) {
      dispatch(getList(""));
    }
  }, []);

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose}>
      <HeaderModal title="meetingAdd" onClose={handleClose} onCancel={handleClose} onSave={onAddHandler} titleSave="add" isEdit={true} />
      <div style={style.main}>
        <div style={style.searchContainer}>
          <div style={style.searchInput}>
            <SvgSearch />
            <Input value={search} onChange={handleSearch} placeholder={phSearch} maxLength={255} />
          </div>
          <RenderWithCondition condition={Boolean(search)}>
            <button style={style.searchButton} onClick={handleResetFilter}>
              <TextFont style={style.searchButtonText}>
                <FormattedMessage id="drop" />
              </TextFont>
            </button>
          </RenderWithCondition>
        </div>

        <div
          onClick={() => {
            setIsAddEventModalVisible(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <TextFont style={style.createEventButtonText}>
            <FormattedMessage id="createMeeting" />
          </TextFont>
        </div>

        <div style={style.mobContainer}>{renderItems}</div>
      </div>

      <ModalRenderWithCondition condition={isAddEventModalVisible}>
        <Modal isVisible={isAddEventModalVisible} meetId={""} setIsVisible={setIsAddEventModalVisible} preventAttachedTasksReset />
      </ModalRenderWithCondition>

      {/* Open meeting detailed page */}
      <ModalRenderWithCondition condition={isDetailedMeetingViewModalVisible}>
        <Modal
          isVisible={isDetailedMeetingViewModalVisible}
          meetId={currenDetailedMeetingId.current}
          setIsVisible={setIsDetailedMeetingViewModalVisible}
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
});

const style = {
  title: {
    width: "33%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  main: {
    paddingTop: 20,
    paddingRight: 12,
    paddingLeft: 12,
  },
  searchContainer: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",
  },
  searchInput: {
    flex: 3,
    display: "flex",
    "flex-direction": "row",
    "border-radius": 10,
    "background-color": "#ECF0EF",
    "padding-left": 8,
    "padding-right": 8,
    "padding-top": 11,
    "padding-bottom": 11,
    "align-items": "center",
  },
  searchButton: {
    flex: 1,
    marginLeft: 14,
  },
  searchButtonText: {
    color: Colors.LIGHT.text.accent,
    fontSize: 16,
    fontWeight: "400",
  },
  mobContainer: {
    height: "82%",
  },
  createEventButtonText: {
    color: Colors.LIGHT.text.grey,
    fontSize: 18,
    fontWeight: "400",
    marginTop: 20,
    marginBottom: 20,
    padding: 0,
  },
  sectionHeaderTitle: {
    fontWeight: "700",
    fontSize: 18,
    color: Colors.LIGHT.text.main,
    marginBottom: 8,
  },
  showMoreContainer: {
    "margin-top": "10px",
    "margin-bottom": "10px",
  },
  showMoreBtnText: {
    fontSize: 18,
    fontWeight: "400",
    color: Colors.LIGHT.text.grey,
  },
  searchNext30daysContainer: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",
    "justify-content": "center",
    margin: "auto",
    "margin-top": "14px",
  },
  searchNext30daysText: {
    paddingLeft: 6,
    color: Colors.LIGHT.text.accent,
    fontWeight: "700",
    fontSize: 18,
  },
  loadMoreBtn: {
    display: "flex",
    "flex-direction": "row",
    "align-items": "center",
  },
  loaderContainer: {
    display: "flex",
    "justify-content": "center",
    width: "90%",
    position: "absolute",
  },
};

const Input = styled.input`
  margin-left: 6px;
  width: 100%;
  ::placeholder {
    color: rgba(60, 60, 67, 0.6);
  }
`;
