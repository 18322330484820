import React, { FC, useMemo, useCallback } from "react";

import dayjs from "dayjs";
import { isNull } from "lodash";
import { FormattedMessage } from "react-intl";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as SvgMessage } from "@assets/message.svg";
import { ReactComponent as SvgPeopleWrapper } from "@assets/people_wrapper.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants, TTypeParticipants, IMeet } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components/LetterAvatar";
import { IExternalUser } from "@services/meet.service";
import { useSetParticipantStatusCommentAsViewedMutation } from "@services/meetApi";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { userSelector } from "@store/user";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { AssistantToast, TextFont } from "@ui";
import { toFormatDate } from "@utils/toFormatTime";

import { ReactComponent as SvgAttend } from "../assets/attend.svg";
import { ReactComponent as SvgMayBe } from "../assets/maybe.svg";
import { ReactComponent as SvgNotAnswer } from "../assets/not_answer.svg";
import { ReactComponent as SvgNotAttend } from "../assets/not_attend.svg";
import { ReactComponent as SvgOwner } from "../assets/owner.svg";

const iconStatus: Record<TTypeParticipants, JSX.Element> = {
  OWNER: <SvgOwner />,
  ATTENDER: <SvgAttend />,
  NON_ATTENDER: <SvgNotAttend />,
  MAYBE_ATTENDER: <SvgMayBe />,
};

interface IProps {
  user: IMeetParticipants | IExternalUser | Partial<IUnitedGroupItem>;
  isExternal?: boolean;
  isSave: boolean;
  handleDeleteItem: (id: string, isExternal: boolean, isGroupAll?: boolean) => void;
  getUser: (userId: string) => IMeetParticipants | IStateUser | undefined;
  setSelectUser: React.Dispatch<React.SetStateAction<IStateUser | undefined>>;
  assistant?: Partial<IStateUser> | null;
  isUserHasEditPermissions?: boolean;
  isModalList?: boolean;
  meet: IMeet;
  participantsAttendance: IParticipantAttendance[] | null;
  isExistMeet?: boolean;
  index?: number;
}

export const ParticipantsListItem: FC<IProps> = ({
  user,
  index,
  isSave,
  isExternal = false,
  getUser,
  handleDeleteItem,
  setSelectUser,
  assistant,
  isUserHasEditPermissions = false,
  isModalList = false,
  meet,
  participantsAttendance,
  isExistMeet = false,
}) => {
  const [setParticipantStatusCommentAsViewed] = useSetParticipantStatusCommentAsViewedMutation();
  const participant = getUser(user.userId);

  const { currentUser, directorId } = userSelector();

  const isFirstElement = index === 0;
  const isItemDeleteAllowed = useMemo(() => {
    const itemId = user?.user?.id ?? user.id;
    const isMatchedIds = directorId ? true : currentUser?.id !== itemId;

    return isUserHasEditPermissions && user.status !== "OWNER" && isMatchedIds;
  }, [user, isUserHasEditPermissions, currentUser]);

  const isNotFired = useMemo(() => user?.user?.active === true || user?.user?.active === undefined, [user]);
  const currentUserName = useMemo(
    () =>
      `${(user?.user?.lastName && user?.user?.lastName[0]?.toUpperCase()) ?? ""}
      ${(user?.user?.firstName && user?.user?.firstName[0]?.toUpperCase()) ?? ""}`,
    [user],
  );

  const borderColorStatus = useCallback(
    (item: IMeetParticipants) => {
      const busyStatus = getUser(item.userId)?.busy;

      if (isNull(busyStatus)) {
        return Colors.DARK.grey;
      }

      if (!isSave) {
        const attendance = participantsAttendance?.filter((i) => i.userId === item.userId)[0];
        return attendance?.meetings.length ? Colors.LIGHT.red : Colors.LIGHT.green;
      }

      return getUser(item.userId)?.busy ? Colors.LIGHT.red : Colors.LIGHT.green;
    },
    [isSave, participantsAttendance],
  );

  const clickHandler = (uid: string) => {
    if (!isExternal) {
      setSelectUser(getUser(uid));
    }
  };

  const handleHoverComment = (val: boolean) => {
    if (val) {
      if (!(user as IMeetParticipants).statusCommentIsViewed) {
        setParticipantStatusCommentAsViewed({
          id: meet.id,
          date: toFormatDate(dayjs(meet.startTime)),
          userId: user.userId,
          repeat: !!meet.repeat,
        });
      }
    }
  };

  const sizes = {
    icon: isModalList ? 32 : 38,
    button: isModalList ? 50 : 58,
  };

  const isGroupAll = useMemo(() => user?.type === "ALL_IN_SPACE", [user, meet]);
  const isGroupAllSaved = useMemo(() => meet?.presetGroups?.[0]?.type === "ALL_IN_SPACE", [user, meet]);

  const textUserName = useMemo(() => {
    if (isGroupAll) {
      return `Выбрана  группа "${user.name}"`;
    }
    return isExternal ? `${user?.firstName} ${user?.lastName}` : `${participant?.lastName ?? ""} ${participant?.firstName ?? ""}`;
  }, [user]);

  const renderIconStatus = (user: IMeetParticipants): JSX.Element => {
    if (meet.externalId && meet.externalOwnerUserId === user.id) return iconStatus.OWNER;
    if (user.email) return null;
    return iconStatus[user.status] ?? <SvgNotAnswer />;
  };

  return (
    <>
      <button
        key={user.userId}
        style={{ ...styles.item, height: sizes.button, ...(isModalList && { borderBottom: `1px solid ${Colors.LIGHT.lighGrey}` }) }}
        onClick={() => clickHandler(user.userId)}
      >
        <div style={{ display: "flex", width: "93%", height: "100%" }}>
          <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
            <RenderWithCondition condition={isExternal && !isGroupAll}>
              <>
                <LetterAvatar
                  userName={user?.firstName}
                  lastName={user?.lastName}
                  style={{ backgroundColor: Colors.DARK.asphalt }}
                  textStyle={{ color: Colors.LIGHT.white }}
                />
                <div style={{ position: "absolute", right: 5, bottom: 0 }}>{renderIconStatus(user)}</div>
              </>
            </RenderWithCondition>
            <RenderWithCondition condition={isGroupAll}>
              <IconWrap>
                <SvgPeopleWrapper width={37} height={37} fill={Colors.LIGHT.orange} />
              </IconWrap>
            </RenderWithCondition>
            <RenderWithCondition condition={!isExternal && !user?.name}>
              <>
                <RenderWithCondition condition={!isNotFired}>
                  <LetterAvatar styleTitle={{ color: "#fff" }} style={{ backgroundColor: "#A1B6AD" }} userName={currentUserName} notCut />
                </RenderWithCondition>
                <RenderWithCondition condition={isNotFired}>
                  <>
                    <UserAvatar
                      avatarFileId={participant?.avatarFileId ?? null}
                      style={{
                        width: sizes.icon,
                        height: sizes.icon,
                        borderRadius: sizes.icon,
                        marginRight: 8,
                        ...(!user.status && { border: `2px solid ${borderColorStatus(user)}` }),
                      }}
                    />
                    <div style={{ position: "absolute", right: 5, bottom: 0 }}>{renderIconStatus(user)}</div>
                  </>
                </RenderWithCondition>
              </>
            </RenderWithCondition>
          </div>
          <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <TextFont
              type="bold"
              size={16}
              weight="700"
              style={{ marginBottom: 0, textDecorationLine: user?.deleted ? "line-through" : "none" }}
            >
              {textUserName}
            </TextFont>

            <RenderWithCondition condition={isNotFired && !isGroupAll}>
              <TextFont size={14} style={commonStyles.oneOfLines} color={Colors.LIGHT.placeholder.main}>
                {isExternal ? user?.email : participant?.workPosition ?? ""}
              </TextFont>
            </RenderWithCondition>
            <RenderWithCondition condition={!isNotFired && !isGroupAll}>
              <TextFont size={14} style={commonStyles.oneOfLines} color={Colors.LIGHT.placeholder.main}>
                {"Не активен"}
              </TextFont>
            </RenderWithCondition>
          </div>
        </div>
        <div style={styles.remove}>
          <RenderWithCondition
            condition={
              (user as IMeetParticipants).status !== "OWNER" && (user as IMeetParticipants).statusComment && meet.createdByCurrentUser
            }
          >
            <button data-tooltip-id={user.statusComment && `tooltip-comment_${user.id}`}>
              <SvgMessage
                fill={(user as IMeetParticipants).statusCommentIsViewed ? Colors.LIGHT.lighGrey : Colors.LIGHT.background.green}
              />
            </button>
          </RenderWithCondition>
          <RenderWithCondition condition={isItemDeleteAllowed}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteItem(isExternal ? user.id : user.userId, isExternal, isGroupAll);
              }}
            >
              <SvgRemove width={12} height={12} fill={Colors.LIGHT.background.grey} />
            </button>
          </RenderWithCondition>
          <RenderWithCondition condition={assistant && user.status === "OWNER"}>
            <div style={{ paddingTop: 8, paddingRight: 6 }}>
              <AssistantToast assistant={assistant} />
            </div>
          </RenderWithCondition>
        </div>
      </button>
      <RenderWithCondition condition={isGroupAllSaved && isExistMeet && isFirstElement}>
        <button
          style={{
            ...styles.item,
            height: sizes.button,
            ...(isModalList && { borderBottom: `1px solid ${Colors.LIGHT.lighGrey}` }),
            cursor: "default",
          }}
        >
          <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
            <IconWrap>
              <SvgPeopleWrapper width={37} height={37} fill={Colors.LIGHT.orange} />
            </IconWrap>
            <TextFont
              type="bold"
              size={16}
              weight="700"
              style={{ marginBottom: 0, textDecorationLine: user.deleted ? "line-through" : "none" }}
            >
              {`Выбрана  группа "${meet?.presetGroups?.[0]?.name}"`}
            </TextFont>
          </div>
        </button>
      </RenderWithCondition>

      {user.statusComment && (
        <ReactTooltip id={`tooltip-comment_${user.id}`} style={styles.toast} place="bottom-start" setIsOpen={handleHoverComment}>
          <div style={styles.wrapperComment}>
            <TextFont size={16} weight="700" type="bold">
              <FormattedMessage id="comment" />
            </TextFont>
            <TextFont weight="400" size={16}>
              {user.statusComment}
            </TextFont>
          </div>
        </ReactTooltip>
      )}
    </>
  );
};

const IconWrap = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const styles = {
  item: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "95% 5%",
    paddingLeft: 8,
    borderRadius: 10,
    backgroundColor: Colors.LIGHT.white,
  },
  remove: {
    width: "7%",
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 20,
    height: "100%",
  },
  wrapperComment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 17,
    paddingRight: 17,
    gap: 8,
  },
  toast: {
    backgroundColor: "white",
    borderRadius: 6,
    padding: 10,
    boxShadow: " 0px 2px 6px 0px #00000017",
    zIndex: 999,
    width: 232,
  },
};
